<template>
  <div id="app">
    <PressReleaseForm />
  </div>
</template>

<script>
import PressReleaseForm from "./components/PressReleaseForm.vue";


export default {
  name: 'App',
  components: {
    PressReleaseForm,
  },
  
}
</script>


